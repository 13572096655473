import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import userService from "services/UserService"

export const updateProfile = createAsyncThunk('user/profile', async (data, { rejectWithValue }) => {
	try {
		const response = await userService.updateProfile(data)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updatePreference = createAsyncThunk('user/preference/update', async (data, { rejectWithValue }) => {
	try {
		const response = await userService.updatePreference(data)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getPreference = createAsyncThunk('user/preference/get', async (_, { rejectWithValue }) => {
	try {
		const response = await userService.getPreference()
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
    status: 'idle',
    error: null,
    preference: {}
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(updateProfile.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(updateProfile.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(updateProfile.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(updatePreference.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(updatePreference.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(updatePreference.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getPreference.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getPreference.fulfilled, (state, action) => {
            state.status = 'success'
            state.preference = action.payload
        })
        .addCase(getPreference.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }
})

export default userSlice.reducer


export const selectPreference = state => state.user.preference