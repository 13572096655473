import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import bookmarkService from 'services/BookmarkService'

export const getBookmarks = createAsyncThunk('bookmark/get', async (_, { rejectWithValue }) => {
	try {
		const response = await bookmarkService.getBookmarks()
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createBookmark = createAsyncThunk('bookmark/create', async (data, { rejectWithValue }) => {
    const { sessionId, questionId } = data
	try {
		const response = await bookmarkService.createBookmark({ sessionId, questionId })
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const removeBookmark = createAsyncThunk('bookmark/remove', async (data, { rejectWithValue }) => {
    const { sessionId, questionId } = data
	try {
		const response = await bookmarkService.removeBookmark({ sessionId, questionId })
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
    status: 'idle',
    error: null,
    bookmarks: {},
}

export const examSlice = createSlice({
    name: 'bookmark',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(createBookmark.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createBookmark.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(createBookmark.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(removeBookmark.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(removeBookmark.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(removeBookmark.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getBookmarks.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getBookmarks.fulfilled, (state, action) => {
            state.status = 'success'
            state.bookmarks = action.payload
        })
        .addCase(getBookmarks.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }
})

export default examSlice.reducer

export const selectBookmarks = state => state.bookmark.bookmarks