import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.exams',
        path: `${APP_PREFIX_PATH}/exams`,
        component: React.lazy(() => import('views/app-views/app/exams'))
    },
    {
        key: 'dashboard.questionexploere',
        path: `${APP_PREFIX_PATH}/question-explorer`,
        component: React.lazy(() => import('views/app-views/app/question-explorer'))
    },
    {
        key: 'dashboard.attempts',
        path: `${APP_PREFIX_PATH}/attempts`,
        component: React.lazy(() => import('views/app-views/app/attempts'))
    },
    {
        key: 'dashboard.performance',
        path: `${APP_PREFIX_PATH}/performance`,
        component: React.lazy(() => import('views/app-views/app/performance'))
    },
    {
        key: 'dashboard.bookmarks',
        path: `${APP_PREFIX_PATH}/bookmarks`,
        component: React.lazy(() => import('views/app-views/app/bookmarks'))
    },
    {
        key: 'dashboard.exam',
        path: `${APP_PREFIX_PATH}/exams/:examId`,
        component: React.lazy(() => import('views/app-views/app/exam'))
    },
    {
        key: 'dashboard.session',
        path: `${APP_PREFIX_PATH}/exams/:examId/sessions/:sessionId`,
        component: React.lazy(() => import('views/app-views/app/session'))
    },
    {
        key: 'dashboard.settings',
        path: `${APP_PREFIX_PATH}/settings`,
        component: React.lazy(() => import('views/app-views/app/profile')),
    },
    {
        key: 'test.default',
        path: `${APP_PREFIX_PATH}/test/:sessionId/attempt/:attemptId`,
        component: React.lazy(() => import('views/app-views/app/test')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'terms-conditions',
        path: `${APP_PREFIX_PATH}/terms-conditions`,
        component: React.lazy(() => import('views/formal-views/terms-conditions')),
    }
]