import fetch from 'auth/FetchInterceptor'

const bookmarkService = {}

bookmarkService.getBookmarks = function() {
    return fetch({
        url: `/question/bookmark`,
        method: 'get',
        params: {},
        cache: true
    })
}
  
bookmarkService.createBookmark = function ({sessionId, questionId}) {
    return fetch({
        url: `/question/bookmark/${sessionId}/${questionId}`,
        method: 'post',
        data: {},
        cache: false
    })
}

bookmarkService.removeBookmark = function ({sessionId, questionId}) {
    return fetch({
        url: `/question/bookmark/remove/${sessionId}/${questionId}`,
        method: 'post',
        data: {},
        cache: false
    })
}

export default bookmarkService