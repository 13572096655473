import fetch from 'auth/FetchInterceptor'

const userService = {}

userService.updateProfile = function (data) {
    return fetch({
        url: `/user/profile`,
        method: 'post',
        data,
        cache: false
    })
}

userService.updatePreference = function (data) {
    return fetch({
        url: `/user/preference`,
        method: 'post',
        data,
        cache: false
    })
}

userService.getPreference = function() {
    return fetch({
        url: `/user/preference`,
        method: 'get',
        params: {},
        cache: true
    })
}

export default userService