import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import attemptService from "services/AttemptService"

export const getSessionQuestionPaper = createAsyncThunk('attempt/paper', async (data, { rejectWithValue }) => {
	const { sessionId } = data
	try {
		const response = await attemptService.getQuestionPaper({sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getExamAttempts = createAsyncThunk('exam/attempts', async (data, { rejectWithValue }) => {
	const { examId } = data
	try {
		const response = await attemptService.getExamAttempts({examId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSessionAttempts = createAsyncThunk('attempt/attempts', async (data, { rejectWithValue }) => {
	const { sessionId } = data
	try {
		const response = await attemptService.getSessionAttempts({sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getExamAttemptsAnalysis = createAsyncThunk('exam/attemptsAnalysis', async (data, { rejectWithValue }) => {
	const { examId } = data
	try {
		const response = await attemptService.getExamAttemptsAnalysis({examId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSessionAttemptsAnalysis = createAsyncThunk('attempt/attemptsAnalysis', async (data, { rejectWithValue }) => {
	const { sessionId } = data
	try {
		const response = await attemptService.getSessionAttemptsAnalysis({sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAttemptReportUrl = createAsyncThunk('attempt/reportUrl', async (data, { rejectWithValue }) => {
	const { key } = data
	try {
		const response = await attemptService.getAttemptAnalysisReportUrl({key})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSessionAttempt = createAsyncThunk('attempt/attempt', async (data, { rejectWithValue }) => {
	const { attemptId, sessionId } = data
	try {
		const response = await attemptService.getSessionAttempt({attemptId, sessionId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createAttempt = createAsyncThunk('attempt/create', async (data, { rejectWithValue }) => {
    const { sessionId } = data
	try {
		const response = await attemptService.createAttempt({ sessionId })
        console.log(response.data)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createEvent = createAsyncThunk('attempt/event', async (events, { rejectWithValue }) => {
	try {
		const response = await attemptService.createEvent(events)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const submitAttempt = createAsyncThunk('attempt/submit', async (data, { rejectWithValue }) => {
    const { sessionId, attemptId } = data
     try {
         const response = await attemptService.submitAttempt({sessionId, attemptId})
         return response.data
     } catch (err) {
         return rejectWithValue(err.response?.data?.message || 'Error')
     }
 })


export const initialState = {
    status: 'idle',
    error: null,
    paper: {},
    attempts: {},
    attemptsAnalysis: {},
    examAttempts: {},
    examAttemptsAnalysis: {},
    attempt: {},
    currentAttempt : {}
}

export const attemptSlice = createSlice({
    name: 'attempt',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getSessionQuestionPaper.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSessionQuestionPaper.fulfilled, (state, action) => {
            state.status = 'success'
            state.paper = action.payload
        })
        .addCase(getSessionQuestionPaper.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getExamAttempts.fulfilled, (state, action) => {
            state.status = 'success'
            state.examAttempts = action.payload
        })
        .addCase(getExamAttempts.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSessionAttempts.pending, (state) => {
            state.status = 'loading'
            state.attempts = {}
        })
        .addCase(getSessionAttempts.fulfilled, (state, action) => {
            state.status = 'success'
            state.attempts = action.payload
        })
        .addCase(getSessionAttempts.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getExamAttemptsAnalysis.fulfilled, (state, action) => {
            state.status = 'success'
            state.examAttemptsAnalysis = action.payload
        })
        .addCase(getExamAttemptsAnalysis.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSessionAttemptsAnalysis.fulfilled, (state, action) => {
            state.status = 'success'
            state.attemptsAnalysis = action.payload
        })
        .addCase(getSessionAttemptsAnalysis.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getSessionAttempt.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSessionAttempt.fulfilled, (state, action) => {
            state.status = 'success'
            state.attempt = action.payload
        })
        .addCase(getSessionAttempt.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(createAttempt.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createAttempt.fulfilled, (state, action) => {
            state.status = 'success'
            state.currentAttempt = {id: action.payload}
        })
        .addCase(createAttempt.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }
})

export default attemptSlice.reducer

export const selectSessionPaper = state => state.attempt.paper
export const selectExamAttempts = state => state.attempt.examAttempts
export const selectSessionAttempts = state => state.attempt.attempts
export const selectExamAttemptsAnalysis = state => state.attempt.examAttemptsAnalysis
export const selectSessionAttemptsAnalysis = state => state.attempt.attemptsAnalysis
export const selectSessionAttempt = state => state.attempt.attempt
export const selectCurrentAttempt = state => state.attempt.currentAttempt
