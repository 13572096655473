import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		data: data,
		cache: false
	})
}

AuthService.googleLogin = function (data) {
	return fetch({
		url: '/auth/googlelogin',
		method: 'post',
		data: data,
		cache: false
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data,
		cache: false
	})
}

AuthService.getUser = function () {
	return fetch({
	  url: `/auth/user`,
	  method: 'get',
	  params: {},
	  cache: true
	})
  }

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post',
		cache: false
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post',
		cache: false
	})
}

export default AuthService;