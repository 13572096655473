import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import baseDataService from "services/BaseDataService";


export const getSubjects = createAsyncThunk('config/subjects', async (data, { rejectWithValue }) => {
	const { examId } = data
	try {
		const response = await baseDataService.getSubjects({examId})
		return response.data.subjects
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
    status: 'idle',
    error: null,
    config: {
        subjects:[],
        difficulty:[
            { id: 'EASY', title: 'Easy' },
            { id: 'MEDIUM', title: 'Medium' },
            { id: 'HARD', title: 'Hard' }
        ],
        questionTypes: [
            { id: 'MCQ', title: 'Single Correct'},
            { id: 'MSQ', title: 'Multiple Correct'},
            { id: 'NT', title: 'Numerical Type'},
        ],
        years: [
            { id:'2024', title:'2024' },
            { id:'2023', title:'2023' },
            { id:'2022', title:'2022' },
            { id:'2021', title:'2021' },
            { id:'2020', title:'2020' },
            { id:'2019', title:'2019' },
            { id:'2018', title:'2018' }
        ]
    }
}

export const sessionSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getSubjects.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getSubjects.fulfilled, (state, action) => {
            state.status = 'success'
            state.config.subjects = action.payload
        })
        .addCase(getSubjects.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }
})

export default sessionSlice.reducer

export const selectAllSubjects = state => state.config.config.subjects
export const selectAllDifficulty = state => state.config.config.difficulty
export const selectAllQuestionTypes = state => state.config.config.questionTypes
export const selectAllYears = state => state.config.config.years